import axios, { AxiosInstance } from "axios";

export class APIClient {
  private static instance: APIClient;
  private _client: AxiosInstance;

  private constructor() {
    this._client = axios.create({
      baseURL: "http://localhost:3000",
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public static getInstance(): APIClient {
    if (!APIClient.instance) {
      APIClient.instance = new APIClient();
    }

    return APIClient.instance;
  }

  public get client(): AxiosInstance {
    return this._client;
  }

  public async me(token: string) {
    return (
      await this._client.get(
        "/me",
        // add token as authorization header
        { headers: { Authorization: `Bearer ${token}` } }
      )
    ).data;
  }

  public async createUserAccountFromDeviceOptionalEmail(
    deviceId: string,
    publicKey: string,
    email?: string
  ) {
    return (
      await this._client.post("/create-user", {
        deviceId,
        publicKey,
        email,
      })
    ).data;
  }
}
