
const darkTheme = {
  background: "#17171C",
  ioliteMed: "#48476B",
  ioniteMain: "#6461ED",
  textMain: "#EFEFF3",
  textSoft: "#737292",
  color1: "#21212E",
};

export const colors = {
  background: darkTheme.background,
  primary: darkTheme.ioniteMain,
  tertiary: darkTheme.color1,
  secondary: darkTheme.ioliteMed,
  text: darkTheme.textMain,
  textSoft: darkTheme.textSoft,

  // constants
  white: "#FFFFFF",
  black: "#000000",
};
