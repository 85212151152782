import { colors } from "./colors";
import { StyleSheet } from "react-native";

export const defaultStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.background,
  },
  inputContainer: {
    width: "100%",
    alignItems: "center",
  },
  mainContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingBottom: 60,
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    color: colors.text,
  },
  input: {
    borderWidth: 0,
    outline: "none",
    borderColor: "none",
    padding: 14,
    marginVertical: 10,
    width: "80%",
    borderRadius: 12,
    backgroundColor: colors.secondary,
    fontSize: 20,
    color: colors.text,
  },
  backButtonText: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.text,
  },
  backButton: {
    marginTop: 80,
    display: "flex",
    flexDirection: "row",
    width: "90%",
    height: 30,
  },
  topContainer: {
    marginTop: 30,
    marginBottom: 30,
    maxHeight: "20%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iconContainer: {
    marginBottom: 40,
  },
  header: {
    maxWidth: 350,
  },
  welcomeText: {
    fontSize: 32,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
    color: colors.text,
  },
  descriptionText: {
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 20,
    color: colors.text,
    textAlign: "center",
  },
  illustration: {
    width: "100%",
    height: 483,
    marginBottom: 20,
  },
  button: {
    backgroundColor: colors.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 25,
    marginBottom: 16,
    width: "90%",
  },
  buttonText: {
    color: colors.text,
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
  secondaryText: {
    color: colors.textSoft,
    fontSize: 16,
  },
  pinContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "90%",
    height: 80,
    marginTop: 6,
  },
  pinInput: {
    width: 50,
    height: 55,
    borderWidth: 0,
    padding: 0,
    borderColor: "none",
    textAlign: "center",
    borderRadius: 12,
    fontSize: 20,
    backgroundColor: colors.secondary,
    color: colors.text,
  },
  // Profile
  profileContainer: {
    flex: 1,
    backgroundColor: colors.background,
    color: colors.text,
  },
  balanceContainer: {
    marginTop: 90,
    alignItems: "center",
    marginBottom: 40,
  },
  balanceText: {
    fontSize: 70,
    fontWeight: "bold",
    color: colors.white,
  },
  menuContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20,
    marginHorizontal: 40,
  },
  menuButton: {},
  transactionList: {
    flex: 1,
  },
  transactionItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 14,
    paddingVertical: 20,
    backgroundColor: colors.tertiary,
    marginHorizontal: 20,
    marginVertical: 5,
    borderRadius: 12,
    minHeight: 80,
    color: colors.text,
  },
  transactionSubtext: {
    fontSize: 14,
    fontWeight: "normal",
    color: colors.text,
  },
  transactionDescription: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.textSoft,
  },
  transactionAmount: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.text,
  },
  bottomBar: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 80,
    borderTopColor: colors.tertiary,
    borderTopWidth: 6,
    backgroundColor: colors.background,

    display: "flex",
    flexDirection: "row",
  },
});
