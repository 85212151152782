import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Svg, Path, Circle, Rect } from "react-native-svg";
import { defaultStyles } from "../../constants/styling";

const PaymentProfileScreen = () => {
  const [balance] = useState("100"); // Example balance

  const personalCards = [
    {
      id: 1,
      description: "Davids Card",
      amount: "$5.50",
      subtext: ["-- 12/12/2020"],
    },
    {
      id: 2,
      description: "Grocery Store",
      amount: "- $32.10",
      subtext: ["0xe....08713", "000111234", "1234567890123456"],
    },
  ];

  // Example transactions (replace with your data source)
  const transactions = [
    { id: 1, description: "Coffee Shop", amount: "- $5.50", subtext: "abcd" },
    {
      id: 2,
      description: "Grocery Store",
      amount: "- $32.10",
      subtext: "abcd",
    },
    { id: 3, description: "Coffee Shop", amount: "- $5.50", subtext: "abcd" },
    {
      id: 4,
      description: "Grocery Store",
      amount: "- $32.10",
      subtext: "abcd",
    },
    { id: 5, description: "Coffee Shop", amount: "- $5.50", subtext: "abcd" },
    {
      id: 6,
      description: "Grocery Store",
      amount: "- $32.10",
      subtext: "abcd",
    },
  ];

  return (
    <View style={styles.profileContainer}>
      {/* Balance Display */}
      <View style={styles.balanceContainer}>
        <Text style={styles.balanceText}>${balance}</Text>
      </View>
      {/* Horizontal Menu */}
      <View style={styles.menuContainer}>
        <TouchableOpacity style={styles.menuButton}>
          <Svg width="64" height="64" viewBox="0 0 64 64" fill="none">
            <Circle cx="32" cy="32" r="32" fill="#D9D9D9" />
          </Svg>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              width: 64,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            send
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.menuButton}>
          <Svg width="64" height="64" viewBox="0 0 64 64" fill="none">
            <Circle cx="32" cy="32" r="32" fill="#D9D9D9" />
          </Svg>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              width: 64,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            receive
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.menuButton}>
          <Svg width="64" height="64" viewBox="0 0 64 64" fill="none">
            <Circle cx="32" cy="32" r="32" fill="#D9D9D9" />
          </Svg>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              width: 64,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            account
          </Text>
        </TouchableOpacity>
      </View>

      {/* Personal Data */}
      <View
        style={{
          flex: 1,
          maxHeight: 290,
        }}
      >
        <ScrollView style={styles.transactionList}>
          {personalCards.map((transaction) => (
            <View key={transaction.id} style={styles.transactionItem}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 6,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 6,
                  }}
                >
                  <Text style={styles.transactionDescription}>
                    {transaction.description}
                  </Text>
                  {transaction.subtext.map((subtext, id) => (
                    <Text key={id} style={styles.transactionSubtext}>
                      {subtext}
                    </Text>
                  ))}
                </View>
              </View>
              <Text style={styles.transactionAmount}>{transaction.amount}</Text>
            </View>
          ))}
        </ScrollView>
      </View>

      {/* Transaction List */}
      <View
        style={{
          marginTop: 0,
          flex: 1,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
        }}
      >
        <Text
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#757880",
            marginBottom: 10,
            marginHorizontal: 20,
          }}
        >
          Recent Transactions
        </Text>

        <ScrollView style={styles.transactionList}>
          {transactions.map((transaction) => (
            <View key={transaction.id} style={styles.transactionItem}>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 6,
                }}
              >
                <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <Circle cx="20" cy="20" r="20" fill="#D9D9D9" />
                </Svg>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 6,
                  }}
                >
                  <Text style={styles.transactionDescription}>
                    {transaction.description}
                  </Text>
                  <Text style={styles.transactionSubtext}>
                    {transaction.subtext}
                  </Text>
                </View>
              </View>
              <Text style={styles.transactionAmount}>{transaction.amount}</Text>
            </View>
          ))}
        </ScrollView>
      </View>

      <View style={styles.bottomBar}>
        <View
          key={1}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity>
            <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
              <Path
                d="M16.5709 20.2852L19.3221 17.5339C19.854 17.002 19.854 16.1397 19.3221 15.6079L16.5709 12.8566"
                stroke="#48476B"
                strokeWidth="3.15714"
                strokeLinecap="round"
              />
              <Path
                d="M19.0486 16.5723L4.19141 16.5723"
                stroke="#48476B"
                strokeWidth="3.15714"
                strokeLinecap="round"
              />
              <Path
                d="M5.42523 9.14062L2.67395 6.38935C2.14209 5.85749 2.14209 4.99518 2.67395 4.46333L5.42522 1.71205"
                stroke="#48476B"
                strokeWidth="3.15714"
                strokeLinecap="round"
              />
              <Path
                d="M2.94754 5.42774L17.8047 5.42773"
                stroke="#48476B"
                strokeWidth="3.15714"
                strokeLinecap="round"
              />
            </Svg>
          </TouchableOpacity>
        </View>

        <View
          key={2}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity>
            <Svg width="26" height="26" viewBox="0 0 26 26" fill="none">
              <Rect
                x="2.78516"
                y="4.64258"
                width="3.71428"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
              <Rect
                x="2.78516"
                y="11.1426"
                width="3.71428"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
              <Rect
                x="2.78516"
                y="17.6426"
                width="3.71428"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
              <Rect
                x="9.28516"
                y="4.64258"
                width="13.9286"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
              <Rect
                x="9.28516"
                y="11.1426"
                width="13.9286"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
              <Rect
                x="9.28516"
                y="17.6426"
                width="13.9286"
                height="3.71428"
                rx="1.85714"
                fill="#48476B"
              />
            </Svg>
          </TouchableOpacity>
        </View>

        <View
          key={3}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity>
            <Svg width="22" height="23" viewBox="0 0 22 23" fill="none">
              <Path
                id="Rectangle 74"
                d="M0.710938 10.8499C0.710938 9.52374 1.31823 8.27056 2.35915 7.44878L8.31748 2.74484C9.89182 1.50194 12.1134 1.50194 13.6877 2.74484L19.6461 7.44878C20.687 8.27056 21.2943 9.52374 21.2943 10.8499V17.9583C21.2943 20.3516 19.3542 22.2917 16.9609 22.2917H15.3359C14.7376 22.2917 14.2526 21.8066 14.2526 21.2083V17.9583C14.2526 16.7617 13.2826 15.7917 12.0859 15.7917H9.91927C8.72265 15.7917 7.7526 16.7617 7.7526 17.9583V21.2083C7.7526 21.8066 7.26758 22.2917 6.66927 22.2917H5.04427C2.65103 22.2917 0.710938 20.3516 0.710938 17.9583L0.710938 10.8499Z"
                fill="#EFEFF3"
                stroke="#EFEFF3"
                strokeWidth="0.5"
              />
            </Svg>
          </TouchableOpacity>
        </View>

        <View
          key={4}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity>
            <Svg width="24" height="22" viewBox="0 0 24 22" fill="none">
              <Path
                d="M5.5 9.95898V19.059H18.5V9.95898H5.5Z"
                fill="#48476B"
                stroke="#48476B"
                strokeWidth="2.16667"
                strokeLinecap="round"
              />
              <Path
                d="M9.07422 19.059V9.95898"
                stroke="#191921"
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <Path
                d="M12 16.2422V9.74219"
                stroke="#48476B"
                strokeWidth="2.16667"
                strokeLinecap="round"
              />
              <Path
                d="M17.4141 16.2422V9.74219"
                stroke="#48476B"
                strokeWidth="2.16667"
                strokeLinecap="round"
              />
              <Path
                d="M14.9258 19.059V9.95898"
                stroke="#191921"
                strokeWidth="2.6"
                strokeLinecap="round"
              />
              <Path
                d="M3.33203 20.0352H20.6654"
                stroke="#48476B"
                strokeWidth="3.25"
                strokeLinecap="round"
              />
              <Path
                d="M10.8222 2.27138L1.31881 8.42062C1.21004 8.491 1.25988 8.65977 1.38943 8.65977H22.609C22.7386 8.65977 22.7884 8.491 22.6796 8.42062L13.1763 2.27138C12.46 1.80792 11.5384 1.80792 10.8222 2.27138Z"
                fill="#48476B"
                stroke="#48476B"
                strokeWidth="2.16667"
                strokeLinecap="round"
              />
            </Svg>
          </TouchableOpacity>
        </View>
        <View
          key={5}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TouchableOpacity>
            <Svg width="24" height="20" viewBox="0 0 24 20" fill="none">
              <Path
                d="M0.357344 6.47786C0.499141 3.16324 3.2312 0.519531 6.58073 0.519531H17.4141C20.7636 0.519531 23.4956 3.16324 23.6374 6.47786H0.357344Z"
                fill="#48476B"
              />
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.6432 8.10286V13.2487C23.6432 16.689 20.8543 19.4779 17.4141 19.4779H6.58073C3.14046 19.4779 0.351562 16.689 0.351562 13.2487V8.10286H23.6432ZM5.4974 12.9779C5.04866 12.9779 4.6849 13.3416 4.6849 13.7904C4.6849 14.2391 5.04866 14.6029 5.4974 14.6029H10.9141C11.3628 14.6029 11.7266 14.2391 11.7266 13.7904C11.7266 13.3416 11.3628 12.9779 10.9141 12.9779H5.4974Z"
                fill="#48476B"
              />
            </Svg>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
});

export default PaymentProfileScreen;
