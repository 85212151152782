import * as React from "react";

import SplashScreen from "./screens/SplashScreen"; // Your existing main screen
import FirstLastScreen from "./screens/CreateAccount/FirstLastScreen";
import EmailScreen from "./screens/CreateAccount/EmailScreen";
import PasskeyScreen from "./screens/CreateAccount/PasskeyScreen";
import VerifyEmailScreen from "./screens/CreateAccount/VerifyEmailScreen";
import PaymentProfileScreen from "./screens/Profile/PaymentProfileScreen";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { StyleSheet } from "react-native";

import { useNavigationContainerRef } from "@react-navigation/native";

const Stack = createNativeStackNavigator();

function AppScreens() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="Home"
    >
      <Stack.Screen name="Home" component={SplashScreen} />
      <Stack.Screen name="CreateAccount" component={FirstLastScreen} />
      <Stack.Screen name="EmailScreen" component={EmailScreen} />
      <Stack.Screen name="VerifyEmailScreen" component={VerifyEmailScreen} />
      <Stack.Screen
        name="PaymentProfileScreen"
        component={PaymentProfileScreen}
      />

      <Stack.Screen name="PasskeyScreen" component={PasskeyScreen} />
    </Stack.Navigator>
  );
}
function App() {
  // check if window is defined (so if in the browser or in react native)
  const isBrowser = typeof window !== "undefined";

  console.log("isBrowser", isBrowser);

  // if in the browser, render the app in a fixed container
  if (isBrowser) {
    return (
      <div
        style={{
          width: 450,
          height: 860,
          margin: "auto",
          top: 0,
          left: 0,
          backgroundColor: "#f00",
          display: "flex",
          border: "10px solid #999",
          borderRadius: 10,
        }}
      >
        <NavigationContainer>
          <AppScreens />
        </NavigationContainer>
      </div>
    );
  }

  return (
    <NavigationContainer>
      <AppScreens />
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  header: {
    maxWidth: 350,
  },
  welcomeText: {
    fontSize: 32,
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
    color: "#111111",
  },
  descriptionText: {
    lineHeight: 24,
    fontSize: 18,
    marginBottom: 20,
    color: "#111111",
    textAlign: "center",
  },
  illustration: {
    width: "100%",
    height: 543, // Adjust the height accordingly
    marginBottom: 20,
  },
  button: {
    backgroundColor: "#316af6",
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 25,
    marginBottom: 16,
    width: "90%", // Adjust button width as per your design requirement
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
  },
  secondaryText: {
    color: "#316af6",
    fontSize: 16,
  },
});

export default App;
