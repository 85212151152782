import {
  StyleSheet,
  Text,
  View,
  Image,
  Button,
  TouchableOpacity,
} from "react-native";
import React, { useEffect } from "react";
import { APIClient } from "../api/APIClient";
import { defaultStyles } from "../constants/styling";

export default function SplashScreen({ navigation }) {
  useEffect(() => {
    // get /me
    const getMe = async () => {
      try {
        const client = await APIClient.getInstance();

        // check if app is in browser
        if (window.location.href.includes("http")) {
          console.log("Running in browser, skipping /me");

          // check if dummyLogin is true in local storage
          const dummyLogin = localStorage.getItem("dummyLogin");

          if (dummyLogin === "true") {
            console.log("dummyLogin is true, going to home screen");
            navigation.navigate("PaymentProfileScreen");
          }

          return;
        }

        const ExpoEnclave = require("@daimo/expo-enclave").ExpoEnclave;
        const token = await ExpoEnclave.fetchPublicKey("dev");
        const response = await client.me(token);

        // TODO: don't just check for "OK" do something with the response
        if (response === "OK") {
          console.log("Successfully fetched /me");
          // go to home screen
          navigation.navigate("PaymentProfileScreen");
        } else {
          console.log("Failed to fetch /me");
        }
      } catch (error) {
        console.error(error);
      }
    };

    getMe();
  }, []);

  return (
    <View style={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.welcomeText}>Welcome to Stable</Text>
        <Text style={styles.descriptionText}>
          Stable is a self-custody bank built from the ground up for
          crypto-natives.
        </Text>
      </View>

      {/* Welcome Text */}

      {/* Illustration */}
      <Image
        source={require("../assets/stable-illustration.png")}
        style={styles.illustration}
        resizeMode="contain"
      />

      {/* Buttons */}
      <TouchableOpacity
        style={styles.button}
        onPress={() => navigation.navigate("CreateAccount")}
      >
        <Text style={styles.buttonText}>Create Account</Text>
      </TouchableOpacity>

      <TouchableOpacity>
        <Text style={styles.secondaryText}>I already have an account</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  ...defaultStyles,
});
