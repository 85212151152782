import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Circle, Path, Svg } from "react-native-svg";
import { defaultStyles } from "../../constants/styling";

const FirstLastScreen = ({ navigation }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  return (
    <View style={styles.container}>
      {/* Back Button */}
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
        style={styles.backButton}
      >
        <Text style={styles.backButtonText}>
          <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <Path
              d="M11 20L2 11M2 11L11 2M2 11L20 11"
              stroke="#757880"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </Text>
      </TouchableOpacity>

      <View style={styles.topContainer}>
        <View style={styles.iconContainer}>
          <Svg
            // xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <Circle cx="32" cy="32" r="32" fill="#F1F1FF" />
            <Svg
              width="64"
              height="64"
              viewBox="0 0 30 40"
              fill="none"
              transform="translate(5, 7)"
            >
              <Circle
                cx="5"
                cy="5"
                r="5"
                transform="matrix(-1 0 0 1 15 1.75)"
                fill="#6461ED"
                stroke="#6461ED"
                strokeWidth="1.875"
              />
              <Path
                d="M1.25 19.1683C1.25 18.0929 1.92607 17.1335 2.93886 16.7718C7.50505 15.1411 12.4949 15.1411 17.0611 16.7718C18.0739 17.1335 18.75 18.0929 18.75 19.1683V20.8127C18.75 22.297 17.4354 23.4372 15.966 23.2273L14.773 23.0569C11.6071 22.6046 8.39294 22.6046 5.22703 23.0569L4.03401 23.2273C2.56463 23.4372 1.25 22.297 1.25 20.8127V19.1683Z"
                fill="#6461ED"
                stroke="#6461ED"
                strokeWidth="1.875"
              />
            </Svg>
          </Svg>
        </View>

        <Text style={styles.headerText}>Whats Your Name</Text>
      </View>

      <View style={styles.mainContainer}>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            placeholder="First Name"
            value={firstName}
            onChangeText={setFirstName}
          />

          <TextInput
            style={styles.input}
            placeholder="Last Name"
            value={lastName}
            onChangeText={setLastName}
          />
        </View>
        {/* Next Button */}
        <TouchableOpacity
          onPress={() => {
            if (firstName == "" || lastName == "") {
              alert("Please enter your first and last name.");
              return;
            }
            navigation.navigate("EmailScreen", { firstName, lastName });
          }}
          style={styles.button}
        >
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
});

export default FirstLastScreen;
