import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Circle, Path, Svg } from "react-native-svg";
import { defaultStyles } from "../../constants/styling";

const EmailScreen = ({ navigation, route }) => {
  const [email, setEmail] = useState("");

  const firstName = route.params.firstName;
  const lastName = route.params.lastName;

  if (!firstName || !lastName || firstName == "" || lastName == "") {
    navigation.navigate("FirstLastScreen");
  }

  async function submitCreateAccount(firstName, lastName, email) {
    if (email == "") {
      alert("Please enter your email");
      return;
    }

    navigation.navigate("VerifyEmailScreen", {
      firstName: firstName,
      lastName: lastName,
      email: email,
    });
  }

  return (
    <View style={styles.container}>
      {/* Back Button */}
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
        style={styles.backButton}
      >
        <Text style={styles.backButtonText}>
          <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <Path
              d="M11 20L2 11M2 11L11 2M2 11L20 11"
              stroke="#757880"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </Text>
      </TouchableOpacity>
      <View style={styles.topContainer}>
        <View style={styles.iconContainer}>
          <Svg
            // xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <Circle cx="32" cy="32" r="32" fill="#F1F1FF" />
            <Svg
              width="64"
              height="64"
              viewBox="0 0 30 50"
              fill="none"
              transform="translate(1, 13)"
            >
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.5625 7.25C0.5625 3.28045 3.78045 0.0625 7.75 0.0625H20.25C24.2195 0.0625 27.4375 3.28045 27.4375 7.25V14.75C27.4375 18.7195 24.2195 21.9375 20.25 21.9375H7.75C3.78045 21.9375 0.5625 18.7195 0.5625 14.75V7.25ZM7.4 6.05C6.73726 5.55294 5.79706 5.68726 5.3 6.35C4.80294 7.01274 4.93726 7.95294 5.6 8.45L11.6 12.95C13.0222 14.0167 14.9778 14.0167 16.4 12.95L22.4 8.45C23.0627 7.95294 23.1971 7.01274 22.7 6.35C22.2029 5.68726 21.2627 5.55294 20.6 6.05L14.6 10.55C14.2444 10.8167 13.7556 10.8167 13.4 10.55L7.4 6.05Z"
                fill="#6461ED"
              />
            </Svg>
          </Svg>
        </View>

        <Text style={styles.headerText}>Whats Your Email?</Text>
      </View>

      <View style={styles.mainContainer}>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            placeholder="sample@email.com"
            value={email}
            onChangeText={setEmail}
            keyboardType="email-address"
            autoCapitalize="none"
          />
        </View>

        {/* Next Button */}
        <TouchableOpacity
          onPress={async () =>
            await submitCreateAccount(firstName, lastName, email)
          }
          style={styles.button}
        >
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
});

export default EmailScreen;
