import React, { useRef, useState } from "react";
import {
  Clipboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Circle, Path, Svg } from "react-native-svg";
import { defaultStyles } from "../../constants/styling";

const PinInput = ({ index, value, onValueChange, onKeyPress, refInput }) => (
  <TextInput
    style={styles.pinInput}
    keyboardType="number-pad"
    maxLength={1}
    onChangeText={(text) => onValueChange(index, text)}
    onKeyPress={({ nativeEvent }) => onKeyPress(index, nativeEvent)}
    ref={refInput}
    value={value}
  />
);

const VerifyEmailScreen = ({ navigation, route }) => {
  const [pin, setPin] = useState(Array(6).fill(""));
  const pinInputRefs = Array.from({ length: 6 }, () => useRef(null));

  const firstName = route.params.firstName;
  const lastName = route.params.lastName;
  const email = route.params.email;

  if (firstName == "" || lastName == "" || email == "") {
    navigation.navigate("FirstLastScreen");
  }

  const handlePinChange = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < 5) {
      pinInputRefs[index + 1].current.focus();
    }
  };

  const handleKeyPress = (index, { key }) => {
    if (key === "Backspace" && index > 0 && !pin[index]) {
      pinInputRefs[index - 1].current.focus();
    }
  };

  const pasteCode = async () => {
    const clipboardContent = await Clipboard.getString();
    if (clipboardContent.length === 6) {
      setPin(clipboardContent.split(""));
    }
  };

  return (
    <View style={styles.container}>
      {/* Back Button */}
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
        style={styles.backButton}
      >
        <Text style={styles.backButtonText}>
          {" "}
          <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <Path
              d="M11 20L2 11M2 11L11 2M2 11L20 11"
              stroke="#757880"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </Text>
      </TouchableOpacity>
      <View style={styles.topContainer}>
        <View style={styles.iconContainer}>
          <Svg
            // xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <Circle cx="32" cy="32" r="32" fill="#F1F1FF" />
            <Svg
              width="64"
              height="64"
              viewBox="0 0 30 50"
              fill="none"
              transform="translate(1, 11)"
            >
              <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.75 0.5625C3.78045 0.5625 0.5625 3.78045 0.5625 7.75V20.25C0.5625 24.2195 3.78045 27.4375 7.75 27.4375H20.25C24.2195 27.4375 27.4375 24.2195 27.4375 20.25V7.75C27.4375 3.78045 24.2195 0.5625 20.25 0.5625H7.75ZM12.7188 13.125C12.1617 11.9781 10.9857 11.1875 9.625 11.1875C7.72652 11.1875 6.1875 12.7265 6.1875 14.625C6.1875 16.5235 7.72652 18.0625 9.625 18.0625C10.9857 18.0625 12.1617 17.2719 12.7188 16.125L20.875 16.125C21.7034 16.125 22.375 15.4534 22.375 14.625C22.375 14.1513 22.1554 13.7289 21.8125 13.454V12.125C21.8125 11.6072 21.3928 11.1875 20.875 11.1875C20.3572 11.1875 19.9375 11.6072 19.9375 12.125V13.125H18.6875V12.125C18.6875 11.6072 18.2678 11.1875 17.75 11.1875C17.2322 11.1875 16.8125 11.6072 16.8125 12.125V13.125L12.7188 13.125ZM10.9133 13.7406C10.732 13.9886 10.625 14.2943 10.625 14.625C10.625 14.9557 10.732 15.2614 10.9133 15.5094C10.6316 15.9189 10.1597 16.1875 9.625 16.1875C8.76206 16.1875 8.0625 15.4879 8.0625 14.625C8.0625 13.7621 8.76206 13.0625 9.625 13.0625C10.1597 13.0625 10.6316 13.3311 10.9133 13.7406Z"
                fill="#6461ED"
              />
            </Svg>
          </Svg>
        </View>

        <Text style={styles.headerText}>Verify your Email</Text>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Text style={{ color: "#777", marginRight: 5 }}>Code sent to</Text>
          <Text
            style={{
              color: "#6461ED",
            }}
          >
            {email}
          </Text>
        </View>
      </View>

      <View style={styles.mainContainer}>
        <View style={styles.inputContainer}>
          <View style={styles.pinContainer}>
            {pin.map((p, index) => (
              <PinInput
                key={index}
                index={index}
                refInput={pinInputRefs[index]}
                value={p}
                onValueChange={handlePinChange}
                onKeyPress={handleKeyPress}
              />
            ))}
          </View>
          {/* 
          <TouchableOpacity onPress={pasteCode} style={styles.pasteButton}>
            <Text style={styles.pasteButtonText}>Paste Code</Text>
          </TouchableOpacity> */}
        </View>

        {/* Next Button */}
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("PasskeyScreen", {
              firstName,
              lastName,
              email,
            })
          }
          style={styles.button}
        >
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
});

export default VerifyEmailScreen;
