import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Circle, Svg, Path } from "react-native-svg";
import { APIClient } from "../../api/APIClient";
import { defaultStyles } from "../../constants/styling";

const PasskeyScreen = ({ navigation, route }) => {
  const firstName = route.params.firstName;
  const lastName = route.params.lastName;
  const email = route.params.email;

  if (firstName == "" || lastName == "") {
    navigation.navigate("FirstLastScreen");
  }

  const account = "dev";
  const isVerified = false;
  const signature = "TBD";

  const checkVerification = async () => {
    const ExpoEnclave = require("@daimo/expo-enclave").ExpoEnclave;
    try {
      // Fetch existing key or create a new one
      let publicKey;
      try {
        console.log("Fetching public key");
        publicKey = await ExpoEnclave.fetchPublicKey(account);
        if (!publicKey) {
          throw new Error("No public key found");
        }
      } catch (error) {
        console.log("No public key found, creating a new one");
        publicKey = await ExpoEnclave.createKeyPair(account);
      }

      if (!publicKey) {
        throw new Error("No public key found");
      }

      return publicKey;
    } catch (error) {
      console.error(error);
    }
  };

  async function addDeviceToAccount() {
    // check if app is in browser
    if (window.location.href.includes("http")) {
      console.log(
        "Running in browser, verifying device and going to home screen"
      );

      // set dummyLogin to true in local storage
      localStorage.setItem("dummyLogin", "true");

      const publicKey = "TBD";
      navigation.navigate("PaymentProfileScreen", {
        firstName,
        lastName,
        email,
        account,
        publicKey,
        isVerified,
        signature,
      });

      return;
    }

    let publicKey = await checkVerification();

    if (!publicKey) {
      alert("No public key found");
      return;
    }

    const apiClient = APIClient.getInstance();
    const json = await apiClient.createUserAccountFromDeviceOptionalEmail(
      /*deviceId*/ account,
      /*publicKey*/ publicKey,
      /*email?*/ email
    );

    navigation.navigate("PaymentProfileScreen", {
      firstName,
      lastName,
      email,
      account,
      publicKey,
      isVerified,
      signature,
    });
  }

  return (
    <View style={styles.container}>
      {/* Back Button */}
      <TouchableOpacity
        onPress={() => {
          navigation.goBack();
        }}
        style={styles.backButton}
      >
        <Text style={styles.backButtonText}>
          <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
            <Path
              d="M11 20L2 11M2 11L11 2M2 11L20 11"
              stroke="#757880"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </Svg>
        </Text>
      </TouchableOpacity>
      <View style={styles.topContainer}>
        <View style={styles.iconContainer}>
          <Svg
            // xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
          >
            <Circle cx="32" cy="32" r="32" fill="#F1F1FF" />
          </Svg>
        </View>

        <Text style={styles.headerText}>Sync your device</Text>
      </View>

      <View style={styles.mainContainer}>
        <View style={styles.inputContainer}>
          <TouchableOpacity
            onPress={async () => {
              await addDeviceToAccount();
            }}
            style={{ ...styles.button }}
          >
            <Text style={styles.buttonText}>📱 Sync</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  ...defaultStyles,
});

// const styles = StyleSheet.create({
//   inputContainer: {
//     width: "100%",
//     alignItems: "center",
//   },
//   mainContainer: {
//     flex: 1,
//     alignItems: "center",
//     justifyContent: "space-between",
//     width: "100%",
//     paddingBottom: 60,
//   },
//   backButtonText: {
//     fontSize: 18,
//     fontWeight: "bold",
//     color: "#6461ED",
//   },
//   backButton: {
//     marginTop: 80,
//     display: "flex",
//     flexDirection: "row",
//     width: "90%",
//     height: 30,
//   },
//   topContainer: {
//     marginTop: 30,
//     marginBottom: 30,
//     maxHeight: "20%",
//     flex: 1,
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   iconContainer: {
//     marginBottom: 40,
//   },
//   button: {
//     backgroundColor: "#6461ED",
//     paddingVertical: 12,
//     paddingHorizontal: 20,
//     borderRadius: 25,
//     marginBottom: 16,
//     marginTop: 20,
//     width: "80%",
//   },
//   buttonText: {
//     color: "#fff",
//     fontWeight: "bold",
//     fontSize: 18,
//     textAlign: "center",
//   },
//   container: {
//     flex: 1,
//     alignItems: "center",
//     justifyContent: "flex-start",
//     backgroundColor: "#fff",
//   },
//   headerText: {
//     fontSize: 24,
//     fontWeight: "bold",
//     marginBottom: 20,
//   },
//   input: {
//     borderWidth: 0,
//     borderColor: "#ddd",
//     padding: 14,
//     marginVertical: 10,
//     width: "80%",
//     borderRadius: 12,
//     backgroundColor: "#F6F6FA",
//     fontSize: 20,
//   },
//   text: {
//     marginTop: 20,
//     fontSize: 18,
//   },
// });

export default PasskeyScreen;
